import "core-js/modules/es.array.includes.js";
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2';
import useSettingsStore from '~/stores/settingsStore';
import useUserStore from '~/stores/userStore';
export default defineNuxtPlugin(function (nuxtApp) {
  var _ref = nuxtApp.nuxt2Context,
    app = _ref.app,
    $pinia = _ref.$pinia;
  $pinia.use(createPersistedStatePlugin({
    filter: function filter(mutation) {
      return !['branding', 'app'].includes(mutation.storeId);
    }
  }));
  app.router.onReady(function () {
    var _useSettingsStore = useSettingsStore(),
      initSettings = _useSettingsStore.initSettings,
      initBranding = _useSettingsStore.initBranding;
    var _useUserStore = useUserStore(),
      loggedIn = _useUserStore.loggedIn;
    if (loggedIn) {
      return initSettings();
    }
    return initBranding(true);
  });
});