<script>
import { computed, defineComponent } from 'vue-demi';
import { intervalToDuration } from 'date-fns';
import { UiList, UiListMenuItem } from '@frontend/ui-kit';

import { useI18n } from '~/plugins/i18n';
import useRecentPagesStore from '~/stores/recentPagesStore';

export default defineComponent({
    components: {
        UiListMenuItem,
        UiList,
    },

    setup() {
        const i18n = useI18n();
        const resentPagesStore = useRecentPagesStore();
        const recentPages = computed(() => resentPagesStore.items);

        function getLabel(name, route) {
            if (name) return i18n.t(name);
            if (route.includes('/release')) return i18n.t('global.whatsnew');
            if (route.includes('/docs/')) return i18n.t('global.support');
            return i18n.t(route);
        }

        const items = computed(() => recentPages.value.map((el) => {
            const label = getLabel(el.name, el.route);
            const link = el.route;
            let breadcrumbs = '';

            if (el.breadcrumbs?.length > 1) {
                const formatedBreadcrumbs = [...el.breadcrumbs];
                formatedBreadcrumbs.pop();
                breadcrumbs = `${i18n.t('global.main')}/${formatedBreadcrumbs.map(el => i18n.t(el.name)).join('/')}`;
            }

            const timeInterval = intervalToDuration({
                start: new Date(el.timestamp),
                end: new Date(),
            });

            let timeTitle = '';

            if (timeInterval.years) {
                timeTitle = i18n.tc('global.time.declensions.year', timeInterval.years);
            } else if (timeInterval.months) {
                timeTitle = i18n.tc('global.time.declensions.month', timeInterval.months);
            } else if (timeInterval.weeks) {
                timeTitle = i18n.tc('global.time.declensions.week', timeInterval.weeks);
            } else if (timeInterval.days) {
                timeTitle = i18n.tc('global.time.declensions.day', timeInterval.days);
            } else if (timeInterval.hours) {
                timeTitle = i18n.tc('global.time.declensions.hour', timeInterval.hours);
            } else if (timeInterval.minutes) {
                timeTitle = i18n.tc('global.time.declensions.minute', timeInterval.minutes);
            }

            const timestamp = `${timeTitle || i18n.t('global.time.less_than_a_minute')} ${i18n.t('global.time.ago')}`;

            const subLabel = breadcrumbs ? `${timestamp} <br> ${breadcrumbs}` : timestamp;

            return {
                label,
                href: link,
                to: link.includes('old') ? '' : link,
                subLabel,
                timestamp,
                breadcrumbs,
            };
        }));

        return {
            items,
        };
    },
});
</script>

<template lang="pug">
UiList
    UiListMenuItem(
        v-for="{href, to, label, timestamp, breadcrumbs} of items"
        :newTab="false"
        :href="href"
        :to="to"
        :key="href || to"
        @click="$emit('click')"
    )
        .recent-item
            .recent-label {{ label }}
            .recent-sub-label
                | {{ timestamp }}
                br
                | {{ breadcrumbs }}
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/components/styles/colors";

.recent-item {
    display: flex;
    flex-flow: column;
}

.recent-label {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 2px;
}

.recent-sub-label {
    font-size: 12px;
    line-height: 16px;
    color: $ui-color-text-secondary;
}
</style>
