import { useRoute } from 'vue-router/composables';

export const UiTeleportNames = {
    Header: {
        Left: 'header-left-teleport',
        Right: 'header-right-teleport',
        Bottom: 'header-bottom-teleport',
    },
};

export function useHeaderTeleport(teleportId = '') {
    const route = useRoute();

    const id = teleportId || route.name;

    const teleportLeftName = `${UiTeleportNames.Header.Left}-${id}`;
    const teleportRightName = `${UiTeleportNames.Header.Right}-${id}`;
    const teleportBottomName = `${UiTeleportNames.Header.Bottom}-${id}`;

    return {
        teleportLeftName,
        teleportRightName,
        teleportBottomName,
    };
}
