import set from 'lodash/set';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

export function formatObject(value) {
    return JSON.stringify(value, null, 4);
}

export function objectDiff(oldValue, newValue) {
    return Object.entries(newValue).filter(([key, value]) => {
        if (typeof oldValue[key] === 'object' && oldValue[key] !== null && typeof value === 'object' && value !== null) {
            return objectDiff(oldValue[key], value).length;
        }
        return oldValue[key] !== value;
    });
}

export function cloneDeep<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
}

// Сплющить объект: привести к виду ключ значение
export function flattenObject(model) {
    const result = {};
    const keysTokens = [];

    function _flattenObject(value) {
        if (value !== null && typeof value === 'object') {
            Object.keys(value).forEach((key) => {
                keysTokens.push(key);
                _flattenObject(value[key]);
                keysTokens.pop();
            });
        } else {
            result[keysTokens.join('.')] = value;
        }
    }

    _flattenObject(model);

    return result;
}

// Развернуть объект: построить объект по ключам
export function expandObject(model) {
    const result = {};

    (Array.isArray(model) ? model : Object.entries(model)).forEach(([key, value]) => {
        set(result, key, value);
    });

    return result;
}

export function getFlattedStringObject(value) {
    try {
        return flattenObject(JSON.parse(value));
    } catch (e) {
        console.log(e);
    }

    return {};
}

export function removeFromObject(value, keys) {
    return omit(value, keys);
}
export function separateObject(value, keys) {
    const fullObject = flattenObject(value);
    const result = pick(fullObject, keys);
    const extra = removeFromObject(fullObject, keys);

    return { result, extra };
}

export function clearObjectString(value) {
    return formatObject(JSON.parse(value));
}
