import { defineStore } from 'pinia';
import { ref } from 'vue-demi';

export enum NotificationTypes {
    Success = 'success',
    Error = 'error',
}

interface Notification {
    type: NotificationTypes;
    message: string | any[];
    tc?: boolean;
    closeAfter: number;
    id?: string;
}

function addId(notification: Notification) {
    if (!notification.id) {
        notification.id = new Date().toISOString();
    }
}

const useNotificationsStore = defineStore('notifications', () => {
    const notifications = ref<Notification[]>([]);

    function pushNotification(notification: Notification) {
        if (notification.message) {
            addId(notification);
            notifications.value.unshift(notification);
            if (notification.closeAfter > 0) {
                setTimeout(() => deleteNotification(notification.id as string), notification.closeAfter);
            }
        }
    }

    function deleteNotification(id: string) {
        const index = notifications.value.findIndex(el => el.id === id);
        if (index > -1) {
            notifications.value.splice(index, 1);
        }
    }

    function addSuccess(message: string | any[], { tc = false, closeAfter = 3000 } = {}) {
        pushNotification({
            type: NotificationTypes.Success,
            tc,
            message,
            closeAfter,
        });
    }

    function addError(message: string | any[], { tc = false, closeAfter = 5000 } = {}) {
        pushNotification({
            type: NotificationTypes.Error,
            tc,
            message,
            closeAfter,
        });
    }

    return {
        notifications,
        deleteNotification,
        addSuccess,
        addError,
    };
}, {
    // @ts-ignore
    persistedState: {
        includePaths: [],
    },
});

export default useNotificationsStore;
