<script>
import { UiIconTemplate, UiIconName, UiLink } from '@frontend/ui-kit';

import usePageOptions from '~/assets/utils/usePageOptions';

export default {
    name: 'NuxtError',
    components: {
        UiIconTemplate,
        UiLink,
    },
    layout: 'default',
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        usePageOptions({
            showBack: false,
        });

        return {
            UiIconName,
        };
    },

    head() {
        return {
            title: 'Portal',
        };
    },
};
</script>
<template lang="pug">
.nuxt-error-page
    .err
        UiIconTemplate.icon(:name="UiIconName.UserInterface_InfoUsual" :size="16")
        .title {{ error.message }}
    UiLink(v-if="error.statusCode !== 401" to="/") {{ $t('global.back_to_home') }}
</template>

<style scoped lang="scss">
@import "@frontend/ui-kit/src/components/styles/colors";

.nuxt-error-page {
    background: $ui-color-bg-secondary;
    color: $ui-color-text-secondary;
    text-align: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 300 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .err {
        display: flex;
        gap: 8px;
    }
}
</style>
