import { computed } from 'vue-demi';

import { locale } from '~/plugins/i18n';

export function useReleaseUpdates() {
    const link = computed(() => {
        return `/docs/${locale.value === 'ru' ? 'ru' : 'en'}/updates/`;
    });

    return {
        link,
    };
}

export function useHelp() {
    const link = computed(() => {
        return `/docs/${locale.value}/`;
    });

    return {
        link,
    };
}
