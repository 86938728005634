import "core-js/modules/es.string.link.js";
import { UiPopper, UiAvatar, UiIconTemplate, UiList, UiIconName, UiLink, UiListMenuItem } from '@frontend/ui-kit';
import { useHelp, useReleaseUpdates } from '~/components/header/useDocsLinks';
export default {
  __name: 'MobileMenu',
  props: {
    pages: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(__props) {
    var _useReleaseUpdates = useReleaseUpdates(),
      updates = _useReleaseUpdates.link;
    var _useHelp = useHelp(),
      help = _useHelp.link;
    var openByLocale = ref('');
    function toggle(locale) {
      openByLocale.value = locale !== openByLocale.value ? locale : '';
    }
    return {
      __sfc: true,
      updates: updates,
      help: help,
      openByLocale: openByLocale,
      toggle: toggle,
      UiPopper: UiPopper,
      UiAvatar: UiAvatar,
      UiIconTemplate: UiIconTemplate,
      UiList: UiList,
      UiIconName: UiIconName,
      UiLink: UiLink,
      UiListMenuItem: UiListMenuItem,
      useHelp: useHelp,
      useReleaseUpdates: useReleaseUpdates
    };
  }
};