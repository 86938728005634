<script>
import { defineComponent } from 'vue-demi';
import { UiIconName, UiIconTemplate, UiLinkAsButton, UiStyles } from '@frontend/ui-kit';

import { useApi } from '~/plugins/api';
import useAppStore from '~/stores/appStore';
import { useReleaseUpdates } from '~/components/header/useDocsLinks';
import openLink from '~/assets/utils/openLink';

export default defineComponent({
    components: {
        UiLinkAsButton,
        UiIconTemplate,
    },

    setup() {
        const auth = useApi().auth;

        const store = useAppStore();

        const { link } = useReleaseUpdates();

        async function checkAuth() {
            try {
                store.setLoading(true);
                await auth.me();
                openLink(link.value);
            } finally {
                store.setLoading(false);
            }
        }

        return {
            UiStyles,
            link,
            locale: 'global.whatsnew',
            UiIconName,
            checkAuth,
        };
    },
});
</script>

<template lang="pug">
UiLinkAsButton.updates-button(
    :href="link"
    :newTab="true"
    :theme="UiStyles.UiTheme.Secondary"
    :locale="locale"
    @click.prevent="checkAuth"
)
    template(#leftIcon)
        UiIconTemplate(:name="UiIconName.UserInterface_ExternalLink" :size="16")
</template>

<style lang="scss" scoped>
.updates-button {
    background: none !important;
    font-weight: 500 !important;
    color: #85899B !important;
    white-space: nowrap;
    font-size: 14px !important;
    outline: none !important;

    &:hover {
        background: #F0F0F3 !important;
        color: #85899B !important;
    }

    &::before {
        content: none !important;
    }
}
</style>
