<script>
export default {
    props: {
        title: {
            type: [String, Number],
            default: '',
        },
    },
};
</script>

<template lang="pug">
h1.page-title(v-if="title" :title="$t(title)")
    | {{ $t(title) }}
    slot
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/components/styles/colors";
@import "@frontend/ui-kit/src/components/styles/typography";

.page-title {
    @include ui-typography-XL-2;
    color: $ui-color-text-default;
}
</style>
