import { defineStore } from 'pinia';
import { ref } from 'vue-demi';
import { differenceInDays } from 'date-fns';

import { defaultExtractor, useApiOptions } from '~/plugins/api';
import { treeRequestHandler } from '~/components/areas/areasHelpers';

function suggestRequestHandler(v: {id: string, text: string}[]) {
    return defaultExtractor(v).reduce((res, { id, text }) => {
        res[id] = text;
        return res;
    }, {});
}

function useCacheStore(getData: (args: any) => Promise<any[]>) {
    const lastUpdate = ref();
    const data = ref({});
    let getDataPromise: Promise = undefined as Promise;

    function setValue(v: any[]) {
        lastUpdate.value = Date.now();
        data.value = v;
    }

    // Раз в 3 дня жесткое обновление (с дожиданием), иначе в бекграунде
    async function update(force = false) {
        const _getDataPromise = getData({ limit: 10000 });
        if (force || !lastUpdate.value || differenceInDays(lastUpdate.value, Date.now()) > 3) {
            getDataPromise = _getDataPromise;
        }
        setValue(await _getDataPromise);
    }

    function getById(id: string) {
        return data.value[id] || id;
    }

    function getCacheDataAsync() {
        return getDataPromise || data.value;
    }

    return {
        data,
        lastUpdate,
        getById,
        update,
        getCacheDataAsync,
    };
}

export const useSceneGroupCacheStore = defineStore('scene_groups_cache', () => {
    return {
        ...useCacheStore(useApiOptions('scene_groups', undefined, suggestRequestHandler)),
    };
});

export const useBrandOwnerCacheStore = defineStore('brand_owner_cache', () => {
    return {
        ...useCacheStore(useApiOptions('brand_owner', undefined, suggestRequestHandler)),
    };
});

export const useAreasCacheStore = defineStore('areas_cache', () => {
    return {
        ...useCacheStore(useApiOptions('areas', 'mdTree', treeRequestHandler)),
    };
});
