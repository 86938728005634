import { loginRouter } from '~/assets/helpers/routers';
import { getQuery } from '~/assets/utils/getQuery';
import useSettingsStore from '~/stores/settingsStore';
import { useAccess } from '~/assets/mixins/accessMixin';
import { getVisitUrl } from '~/assets/utils/url';
import useUserStore, { PingCookie, InternalCookie } from '~/stores/userStore';
import { getCookie, setCookie } from '~/assets/utils/cookie';

function redirectOnVisit(redirect, name) {
    const ids = name.replace('#', '').split('/');
    return redirect(getVisitUrl(ids[0], getQuery({ photo: ids[1] })));
}

const error403 = { statusCode: 403, message: 'Permission denied' };

export default async({ route, error, redirect }) => {
    const { user, loggedIn, fetchUser, setUserToken, getUserToken } = useUserStore();

    const routeName = route.name || route.path.replace('/', '');

    if (!loggedIn) {
        if (['logout', 'password-recovery', 'password-update'].includes(routeName)) {
            return;
        }

        const token = getCookie(PingCookie);
        if (token) {
            setUserToken(token);
        } else {
            if (routeName === 'login') return;
            return redirect(loginRouter(route.fullPath));
        }
    }

    if (!getCookie(InternalCookie)) {
        setCookie(InternalCookie, getUserToken());
    }

    const fetcher = fetchUser();
    if (!user) await fetcher;

    if ((routeName === 'index' && !route.hash) || routeName === 'login') return redirect('/visits');

    const id = route.params.id || route.hash || '';
    if (['visits-id', 'visit-id', 'old-visit', 'visits'].includes(routeName)) {
        if (id.includes('#') && !id.includes('=')) {
            return redirectOnVisit(redirect, id);
        }
    }

    if ((routeName.startsWith('visit/') || routeName === 'index') && !id.includes('=')) {
        return redirectOnVisit(redirect, route.hash || routeName.replace('visit/', ''));
    }

    const { canByRole, canByPermission } = useAccess();

    if (route.meta.length > 0) {
        const meta = route.meta[0];
        if (meta.access && !canByPermission(meta.access)) {
            error(error403);
        }

        if (meta.accessRoles && !canByRole(meta.accessRoles)) {
            error(error403);
        }
    }
    if (routeName === 'old-app-page-id' && route.params.id === 'login' && route.params.page === 'site') {
        return redirect('/login');
    }
    const settingsStore = useSettingsStore();
    if ((routeName === 'routes' && !settingsStore.showRoutes) ||
    (routeName === 'sfa-tasks' && !settingsStore.showSfaTasks) ||
    (routeName === 'planogramm' && !settingsStore.showPlanogramm)) {
        error(error403);
    }
};
