<script>
import { defineComponent } from 'vue-demi';
import {
    UiAvatar,
    UiDropDown,
    UiIconName,
    UiIconTemplate,
    UiList,
    UiListItem,
    UiListMenuItem,
    UiTooltip,
} from '@frontend/ui-kit';

import useUserStore from '~/stores/userStore';

export default defineComponent({
    components: {
        UiListMenuItem,
        UiListItem,
        UiList,
        UiDropDown,
        UiAvatar,
        UiTooltip,
        UiIconTemplate,
    },

    setup() {
        const { logout } = useUserStore();

        const menu = [
            { onClick: () => {}, href: '/old/profile', locale: 'nav.profile' },
            { onClick: logout, locale: 'nav.logout' },
        ];

        return {
            menu,
            locale: 'nav.profile',
            UiIconName,
        };
    },
});
</script>

<template lang="pug">
UiDropDown
    template(#opener="{isOpen}")
        UiTooltip(:text="locale" :disabled="isOpen")
            UiAvatar(:data-qa="locale" :status="false")
                UiIconTemplate(:name="UiIconName.Users_Profile" :size="20")
    template(#default)
        UiList
            component(
                v-for="item of menu"
                :is="item.href ? 'UiListMenuItem' : 'UiListItem'"
                :key="item.locale"
                :href="item.href"
                :data-qa="item.locale"
                @click="item.onClick"
            ) {{ $t(item.locale) }}
</template>
