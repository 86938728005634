import { defineStore } from 'pinia';
import { ref } from 'vue-demi';

import type { Breadcrumb } from '~/stores/appStore';

interface RecentPage {
    name: string;
    route: string;
    breadcrumbs?: Breadcrumb[],
    timestamp: object,
}

const useRecentPagesStore = defineStore('recentPages',
    () => {
        const items = ref<RecentPage[]>([]);

        function addItem(item: RecentPage) {
            const newItems = items.value.filter(el => el.route !== item.route);

            if (newItems.length >= 10) {
                newItems.length = 9;
            }

            newItems.unshift(item);

            items.value = newItems;
        }

        function updateTitle(title) {
            if (items.value[0]) {
                items.value[0].name = title;
            }
        }

        return {
            items,
            addItem,
            updateTitle,
        };
    },
);

export default useRecentPagesStore;
