<script>
import { computed, defineComponent } from 'vue-demi';
import { UiAvatar, UiDropDown, UiList, UiListItem, UiTooltip } from '@frontend/ui-kit';

import { useI18n } from '~/plugins/i18n';

export default defineComponent({
    name: 'LocaleSelector',

    components: {
        UiList,
        UiListItem,
        UiAvatar,
        UiTooltip,
        UiDropDown,
    },

    setup() {
        const i18n = useI18n();

        const locales = computed(() => i18n.locales);
        const activeLocale = computed(() => {
            const locale = locales.value.find(i => i.code === i18n.locale);
            localStorage.setItem('userLocale', locale?.code);
            return locale;
        });

        function setLocale(code, close) {
            i18n.setLocale(code);
            close();
        }

        const listOfLocales = computed(() => locales.value.filter(({ code }) => code !== activeLocale.value.code && code !== 'dev').sort((a, b) => a.code.localeCompare(b.code)));

        return {
            listOfLocales,
            activeLocale,
            setLocale,
            locale: 'nav.language',
        };
    },
});
</script>

<template lang="pug">
UiDropDown(:border="false")
    template(#opener="{isOpen}")
        UiTooltip(:text="locale" :disabled="isOpen")
            UiAvatar(:status="false" :data-qa="locale")
                .locale-text {{ activeLocale.abbreviation }}
    template(#default="{close}")
        UiList
            UiListItem(
                v-for="{code, label} of listOfLocales"
                :key="code"
                :data-qa="code"
                @click="setLocale(code, close)"
            ) {{ label || code }}
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/components/styles/colors";

.locale-text {
    display: flex;
    justify-content: center;
    font-size: 13px;
    line-height: 20px;
    color: $ui-color-icon-default;
    font-weight: 700;
    width: 100%;
}
</style>
