export const YandexMetricConfig = {
    id: '72291448',
    webvisor: true,
    clickmap: true,
    trackLinks: true,
    useCDN: true,
    // accurateTrackBounce: true,
};

export const GoogleMetricConfig = {
    appName: 'Ailet Portal',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'GTM-NRRTGB3D',
    },
};
