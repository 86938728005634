<script>
import { UiLoading } from '@frontend/ui-kit';
import { storeToRefs } from 'pinia';

import useAppStore from '~/stores/appStore';

export default {
    name: 'PageLoading',

    components: {
        UiLoading,
    },

    setup() {
        const appStore = useAppStore();
        const { isLoading, loadingMessage } = storeToRefs(appStore);

        return {
            isLoading,
            loadingMessage,
        };
    },
};
</script>

<template lang="pug">
.loading-container(v-show="isLoading")
    UiLoading(
        :isShow="true"
    )
    span.text(v-if="loadingMessage") {{ typeof loadingMessage === 'object' ? $tc(...loadingMessage) : $t(loadingMessage) }}
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/components/styles/spacing";
@import "@frontend/ui-kit/src/components/styles/colors";

.loading-container {
    @include column_with_space(24px);
    z-index: 100000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
}

.text {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: $ui-color-text-default;
}
</style>
