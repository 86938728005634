import { computed, toRefs } from 'vue-demi';
import { UiModalConfirmModule } from '@frontend/ui-kit';
import Loading from '~/components/layout/Loading.vue';
import { useI18n } from '~/plugins/i18n';
import LayoutPageHeader from '~/components/layout/LayoutPageHeader.vue';
import LayoutHeader from '~/components/header/LayoutHeader.vue';
import Notifications from '~/components/notifications/Notifications.vue';
import useStatsStore from '../stores/statsStore';
import useAppStore from '../stores/appStore';
export default {
  name: 'DefaultLayout',
  components: {
    LayoutHeader: LayoutHeader,
    Loading: Loading,
    LayoutPageHeader: LayoutPageHeader,
    Notifications: Notifications,
    UiModalConfirmModule: UiModalConfirmModule
  },
  middleware: 'recent-pages',
  setup: function setup() {
    useStatsStore();
    var route = useRoute();
    var appStore = useAppStore();
    var key = computed(function () {
      // if (route.name === 'visits-id') return 'visits-id';
      return route.fullPath;
      /**
       * Кейсы для проверки:
       * 1. Создать sfa задачу (перебросит на новую страницу), снова создать, должна открыться новая задача
       * 2. При смене активных фоток в визите не должна происходить перезагрузка страницы
       */
    });

    var _toRefs = toRefs(appStore),
      fixedHeader = _toRefs.fixedHeader,
      fullPage = _toRefs.fullPage,
      defaultPadding = _toRefs.defaultPadding,
      darkBackground = _toRefs.darkBackground;
    var i18n = useI18n();
    var title = computed(function () {
      return i18n.t(appStore.title);
    });
    return {
      key: key,
      fixedHeader: fixedHeader,
      fullPage: fullPage,
      title: title,
      defaultPadding: defaultPadding,
      darkBackground: darkBackground
    };
  },
  head: function head() {
    return {
      title: this.title
    };
  }
};