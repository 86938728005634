<script>
import { defineComponent } from 'vue-demi';
import { UiAvatar, UiIconName, UiIconTemplate, UiLink, UiTooltip } from '@frontend/ui-kit';

import { useApi } from '~/plugins/api';
import useAppStore from '~/stores/appStore';
import { useHelp } from '~/components/header/useDocsLinks';
import openLink from '~/assets/utils/openLink';

export default defineComponent({
    components: {
        UiLink,
        UiAvatar,
        UiTooltip,
        UiIconTemplate,
    },

    setup() {
        const auth = useApi().auth;

        const store = useAppStore();

        const { link } = useHelp();

        async function checkAuth() {
            try {
                store.setLoading(true);
                await auth.me();
                openLink(link.value);
            } finally {
                store.setLoading(false);
            }
        }

        return {
            link,
            locale: 'global.support',
            UiIconName,
            checkAuth,
        };
    },
});
</script>

<template lang="pug">
UiTooltip(:text="locale")
    UiLink(:href="link" :newTab="true" :data-qa="locale" @click.native.prevent="checkAuth")
        UiAvatar(:status="false")
            UiIconTemplate(:name="UiIconName.UserInterface_Question" :size="20")
</template>
