import useUserStore, { AccessRole } from '~/stores/userStore';

enum AccessType {
    AllAreas = 'AllAreas',
    Create = 'Create',
    Delete = 'Delete',
    Export = 'Export',
    Manage = 'Manage',
    Menu = 'Menu',
    Read = 'Read',
    Update = 'Update',
}

enum AccessName {
    Analytics = 'analytics',
    AppSettings = 'appsettings',
    Areas = 'areas',
    AssortmentCorrect = 'assortment-correct',
    Billing = 'billing',
    City = 'city',
    Content = 'Content',
    Country = 'country',
    DailyReport = 'daily-report',
    Download = 'download',
    Kpi = 'kpi',
    LinkPriceVisit = 'link-price-visit',
    Logs = 'logs',
    Lot = 'lot',
    Lss = 'lss',
    Metric = 'metric',
    Metrics = 'metrics',
    Monitoring = 'Monitoring',
    Periods = 'periods',
    PlanType = 'Plan-type',
    Plan = 'plan',
    PriceStandards = 'Price-standards',
    ProductCategory = 'product-category',
    Product = 'product',
    Profile = 'profile',
    Questionnaire = 'questionnaire',
    Rbac = 'rbac',
    Region = 'region',
    ReportJobs = 'report-jobs',
    Retailer = 'retailer',
    SceneType = 'scene-type',
    Scheduler = 'scheduler',
    ServiceUser = 'service-user',
    Settings = 'settings',
    ShareExp = 'share-exp',
    Store = 'store',
    Tasks = 'tasks',
    Team = 'team',
    UnifiedList = 'Unified-list',
    User = 'user',
    UserPermissions = 'user_permissions',
    Visit = 'visit',
    VisitManual = 'Visit_manual',
}

const Access = {
    type: AccessType,
    name: AccessName,
    role: AccessRole,
};

export function getAccessPermission(name: AccessName, type: AccessType) {
    return name + type;
}

function useAccess() {
    const store = useUserStore();

    function hasPermission(permission: string) {
        return store.user?.permissions.includes(permission);
    }

    function hasAccess(name: AccessName, type: AccessType) {
        return hasPermission(getAccessPermission(name, type));
    }

    function can(name: AccessName, type: AccessType | AccessType[]) {
        if (store.loggedIn) {
            if (Array.isArray(type)) {
                return Boolean(type.find(t => hasAccess(name, t)));
            }
            return hasAccess(name, type as AccessType);
        }
        return false;
    }

    function canByPermission(permissions: AccessType | AccessType[]) {
        if (store.loggedIn) {
            if (Array.isArray(permissions)) {
                return Boolean(permissions.find(hasPermission));
            }
            return hasPermission(permissions);
        }
        return false;
    }

    function canByRole(type: AccessRole | AccessRole[]) {
        if (store.loggedIn) {
            if (Array.isArray(type)) {
                return type.includes(store.user?.role);
            }
            return store.user?.role === type;
        }
        return false;
    }

    return {
        can,
        canByRole,
        canByPermission,
    };
}

export { useAccess, Access as Acc, AccessType as AT, AccessName as AN, AccessRole as AR };
