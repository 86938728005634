import { defineStore } from 'pinia';
import { ref } from 'vue-demi';

const DEFAULT_UPLOAD_SPEED = 1025 * 150;

const useStatsStore = defineStore('stats', () => {
    const averageInternetSpeed = ref(0);
    const uploads = ref(0);

    function saveUpload(size: number, time: number) {
        uploads.value += 1;

        const speed = size / time;
        averageInternetSpeed.value = (uploads.value - 1) / uploads.value * averageInternetSpeed.value + speed / uploads.value;
    }

    function getEstimatedUploadTime(size: number) {
        return size / (averageInternetSpeed.value || DEFAULT_UPLOAD_SPEED);
    }

    return {
        averageInternetSpeed,
        uploads,
        saveUpload,
        getEstimatedUploadTime,
    };
});

export default useStatsStore;
