import useRecentPagesStore from '~/stores/recentPagesStore';
import useAppStore from '~/stores/appStore';

export default ({ route }) => {
    const appStore = useAppStore();
    const recentPagesStore = useRecentPagesStore();

    if (!route.fullPath.includes('/old/')) {
        recentPagesStore.addItem({
            name: appStore.title,
            route: route.path,
            breadcrumbs: appStore.breadcrumbs,
            timestamp: new Date(),
        });
    }
};
