import { getQuery } from '~/assets/utils/getQuery';
import { useApi } from '~/plugins/api';

export function getExportLink(area_id = undefined, filters = {}, without_area = undefined) {
    return useApi().iris.baseUrl + '/areas/export' + getQuery({
        id: area_id,
        load_users_without_area: without_area === 'users' ? true : undefined,
        load_stores_without_area: without_area === 'stores' ? true : undefined,
        name: filters.name,
    });
}

function filterByName(name, nameRegExp) {
    return nameRegExp.test(name);
}

export function filterAreas(data, nameRegExp) {
    return data.reduce((res, d) => {
        const _children = filterAreas(d._children, nameRegExp);
        if (_children.length || filterByName(d.name, nameRegExp)) res.push({ ...d, _children });
        return res;
    }, []);
}

export function treeReFormatter(data, filters = {}) {
    return data.map((el) => {
        console.log(el);
        return ({
            ...el,
            downloadUrl: getExportLink(el.id, filters),
            _expanded: Boolean(el._children.length && filters.name),
            _children: treeReFormatter(el._children, filters),
        });
    });
}

function treeFormatter(data, filters = {}) {
    return data.map(el => ({
        ...el.item,
        users: el.item.users.map(user => user.name),
        downloadUrl: getExportLink(el.item.id, filters),
        canDelete: !el.children.length,
        _level: el.level,
        _expanded: Boolean(el.children.length && filters.name),
        _children: treeFormatter(el.children, filters),
    }));
}

export function treeRequestHandler(data, filters) {
    return {
        ...data,
        children: treeFormatter(data.children, filters),
    };
}
