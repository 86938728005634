<script lang="ts">
import { defineComponent, computed } from 'vue-demi';
import { UiBreadcrumbs, UiIconButton, UiStyles, UiIconName } from '@frontend/ui-kit';
import { storeToRefs } from 'pinia';

import PageTitle from '~/components/layout/PageTitle.vue';
import { UiTeleportTarget } from '~/components/ui-kit/UiTeleport';
import { UiTeleportNames } from '~/components/ui-kit/UiTeleport/UiTeleportNames';
import useAppStore from '~/stores/appStore';

export default defineComponent({
    components: {
        UiBreadcrumbs,
        PageTitle,
        UiTeleportTarget,
        UiIconButton,
    },

    setup() {
        const { backLink, breadcrumbs, titleShadow, headerTeleportId, title: titleLocale, mobileHeaderBorder } = storeToRefs(useAppStore());

        const teleportLeftName = computed(() => `${UiTeleportNames.Header.Left}-${headerTeleportId.value}`);
        const teleportRightName = computed(() => `${UiTeleportNames.Header.Right}-${headerTeleportId.value}`);
        const teleportBottomName = computed(() => `${UiTeleportNames.Header.Bottom}-${headerTeleportId.value}`);

        return {
            titleLocale,
            breadcrumbs,
            teleportLeftName,
            teleportRightName,
            teleportBottomName,
            titleShadow,
            UiStyles,
            UiIconName,
            backLink,
            mobileHeaderBorder,
        };
    },
});
</script>

<template lang="pug">
.page-header(:class="{'_shadow': titleShadow, '_mobile_border': mobileHeaderBorder}")
    .page-header-top
        .page-header-left
            UiBreadcrumbs.breadcrumbs(:breadcrumbs="breadcrumbs")
            .page-header-title
                UiIconButton.back(
                    v-if="backLink"
                    :size="24"
                    :to="backLink"
                    :href="backLink"
                    :link="true"
                    :padding="false"
                    :name="UiIconName.Arrows_LeftArrow"
                    :theme="UiStyles.UiTheme.Secondary"
                )
                UiTeleportTarget(:name="teleportLeftName")
                    PageTitle(:title="titleLocale")
        .page-header-right
            UiTeleportTarget(:name="teleportRightName")
    UiTeleportTarget(slim :name="teleportBottomName")
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/components/styles/colors";
@import "@frontend/ui-kit/src/components/styles/spacing";
@import "@frontend/ui-kit/src/components/styles/adaptive";

.page-header {
    @include column_with_space(12px);
    padding: 8px 24px 12px;
    background: $ui-color-bg-default;
    //overflow: hidden;
    flex-shrink: 0;

    &._shadow {
        padding-bottom: 16px;
        border-bottom: 2px solid rgba(225, 226, 230, 0.5);
    }

    @include _1024 {
        padding: 12px 16px;

        &._mobile_border {
            border-bottom: 1px solid $ui-color-border-default;
        }
    }
}

.page-header-top {
    @include elements_with_space(12px);
    flex-flow: wrap;
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;
}

.page-header-left {
    overflow: hidden;
}

.page-header-title {
    display: flex;
    align-items: center;
    padding-top: 8px;

    @include _1024 {
        padding-top: 0;
    }
}

.breadcrumbs {
    margin-bottom: 2px;
}

.back {
    margin-right: 8px;
}

.page-header-right {
    margin-left: auto;
}
</style>
